<template>
  <div
    class="
      w-full
      h-full
      flex flex-col
      items-center
      justify-center
      text-white
      tracking-wide
    "
  >
    <elphin-logo style="width: 180px; max-width: 40vw" />
    <div class="text-2xl font-extralight m-5 opacity-60">COMING SOON</div>
    <!--    <register-sticker-->
    <!--      style="width: 80px"-->
    <!--      class="-->
    <!--        mb-5-->
    <!--        opacity-80-->
    <!--        cursor-pointer-->
    <!--        hover:opacity-100-->
    <!--        transition-opacity-->
    <!--        duration-500-->
    <!--      "-->
    <!--    />-->

    <div class="opacity-80 flex flex-col items-center mb-4">
      <div class="text-xs font-extralight">BY</div>
      <hive-logo style="max-width: 40vw; width: 120px" class="m-4" />
      <div class="text-xs font-extralight">IN COLLABORATION WITH</div>
      <embrace-logo style="max-width: 60vw; width: 200px" class="m-4" />
    </div>
    <div class="text-lg font-extralight opacity-80">EOI Now Open</div>
    <div class="text-lg opacity-80">
      <a href="tel:0448 49 48 48">04 48 49 48 48</a>
    </div>
  </div>
</template>

<script>
import ElphinLogo from "@/components/svg/ElphinLogo";
// import RegisterSticker from "@/components/svg/RegisterSticker";
import EmbraceLogo from "@/components/svg/EmbraceLogo";
import HiveLogo from "@/components/svg/HiveLogo";
export default {
  name: "Home",
  components: { HiveLogo, EmbraceLogo, ElphinLogo },
};
</script>
