<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 300 164"
    xml:space="preserve"
  >
    <g style="opacity: 0.8">
      <g>
        <g>
          <path
            style="fill: #ffffff"
            d="M1,85.8V6h50.6v6.2H7.8v29.5h42.9V48H7.8v31.6h43.8v6.2H1z"
          />
          <path style="fill: #ffffff" d="M68.2,85.8V6h6.2v79.8H68.2z" />
          <path
            style="fill: #ffffff"
            d="M100,76.5v31.3h-6.2V28h6.2v9.1c4.1-5.9,11.5-10.5,20.1-10.5c15.4,0,25.8,11.7,25.8,30.3
				c0,18.4-10.4,30.4-25.8,30.4C112,87.2,104.7,83,100,76.5z M139.2,56.8c0-14-7.3-24.6-20.2-24.6c-8,0-15.7,4.8-19,10.3v28.6
				c3.3,5.5,11,10.5,19,10.5C131.9,81.6,139.2,70.8,139.2,56.8z"
          />
          <path
            style="fill: #ffffff"
            d="M200.6,85.8V46.4c0-11.1-5.6-14.2-14-14.2c-7.4,0-15.1,4.7-18.8,9.9v43.7h-6.2V6h6.2v30.7
				c4.2-5,12.4-10.2,20.8-10.2c11.7,0,18.2,5.5,18.2,18.5v40.7H200.6z"
          />
          <path
            style="fill: #ffffff"
            d="M224.5,14.2c0-2.6,2.3-4.7,4.8-4.7c2.6,0,4.8,2,4.8,4.7c0,2.6-2.2,4.8-4.8,4.8
				C226.8,18.9,224.5,16.8,224.5,14.2z M226.2,85.8V28h6.2v57.8H226.2z"
          />
          <path
            style="fill: #ffffff"
            d="M290.7,85.8V46.7c0-11.1-5.6-14.5-14-14.5c-7.4,0-15.1,4.7-18.8,9.9v43.7h-6.2V28h6.2v8.7
				c4.2-5,12.4-10.2,20.8-10.2c11.7,0,18.2,5.7,18.2,18.8v40.4H290.7z"
          />
        </g>
        <g>
          <path
            style="fill: #ffffff"
            d="M12.9,157.3v-33.1H1.2v-2.8h26.6v2.8H16v33.1H12.9z"
          />
          <path
            style="fill: #ffffff"
            d="M25.9,144.3c0-7.6,4.8-13.6,12.4-13.6c7.7,0,12.5,6,12.5,13.6c0,7.6-4.8,13.7-12.5,13.7
				C30.8,158,25.9,151.9,25.9,144.3z M47.9,144.3c0-5.8-3.4-11.1-9.5-11.1c-6,0-9.5,5.3-9.5,11.1c0,5.8,3.4,11.1,9.5,11.1
				C44.5,155.4,47.9,150.1,47.9,144.3z"
          />
          <path
            style="fill: #ffffff"
            d="M80.4,157.3l-7.3-22.2l-7.3,22.2h-2.7l-8.5-26h3l6.9,22.1l7.3-22.1h2.4l7.3,22.1l6.9-22.1h3
				l-8.5,26H80.4z"
          />
          <path
            style="fill: #ffffff"
            d="M114.4,157.3v-17.6c0-5-2.5-6.5-6.3-6.5c-3.3,0-6.8,2.1-8.5,4.5v19.7h-2.8v-26h2.8v3.9
				c1.9-2.3,5.6-4.6,9.4-4.6c5.3,0,8.2,2.6,8.2,8.5v18.2H114.4z"
          />
          <path
            style="fill: #ffffff"
            d="M165.9,157.3v-17.1h-22.8v17.1H140v-35.9h3.1v16h22.8v-16h3.1v35.9H165.9z"
          />
          <path
            style="fill: #ffffff"
            d="M176.3,144.3c0-7.6,4.8-13.6,12.4-13.6c7.7,0,12.5,6,12.5,13.6c0,7.6-4.8,13.7-12.5,13.7
				C181.1,158,176.3,151.9,176.3,144.3z M198.3,144.3c0-5.8-3.4-11.1-9.5-11.1c-6,0-9.5,5.3-9.5,11.1c0,5.8,3.4,11.1,9.5,11.1
				C194.9,155.4,198.3,150.1,198.3,144.3z"
          />
          <path
            style="fill: #ffffff"
            d="M239.5,157.3v-18.2c0-3.7-1.6-5.9-5.2-5.9c-2.8,0-6,2-7.4,4.4v19.7H224v-18.2
				c0-3.7-1.5-5.9-5.2-5.9c-2.8,0-5.9,2.1-7.5,4.5v19.7h-2.8v-26h2.8v3.9c1.1-1.7,4.6-4.6,8.4-4.6c4,0,6.2,2.4,6.8,4.9
				c1.5-2.4,5-4.9,8.6-4.9c4.8,0,7.1,2.7,7.1,7.9v18.7H239.5z"
          />
          <path
            style="fill: #ffffff"
            d="M249.5,144.3c0-7.5,5.3-13.6,12.5-13.6c7.9,0,12.3,6.2,12.3,13.8v0.8h-21.8
				c0.3,5.7,4,10.3,10.2,10.3c3.3,0,6.3-1.2,8.5-3.6l1.5,1.8c-2.6,2.7-5.9,4.2-10.2,4.2C254.9,158,249.5,152.3,249.5,144.3z
				 M262,133.1c-6.1,0-9.2,5.4-9.4,9.9h18.9C271.4,138.6,268.5,133.1,262,133.1z"
          />
          <path
            style="fill: #ffffff"
            d="M279.3,153.8l1.7-2c1.7,2.1,4.7,3.8,8.4,3.8c4.4,0,6.9-2.3,6.9-5.3c0-7.1-16.4-3.1-16.4-12.4
				c0-4,3.4-7.2,9.3-7.2c4.5,0,7.4,1.7,9.3,3.8l-1.6,2c-1.5-2-4.3-3.4-7.8-3.4c-4,0-6.5,2-6.5,4.7c0,6.5,16.4,2.5,16.4,12.5
				c0,4.2-3.2,7.6-9.7,7.6C285.1,158,281.8,156.7,279.3,153.8z"
          />
        </g>
        <line
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 6;
            stroke-miterlimit: 10;
          "
          x1="104.3"
          y1="106.7"
          x2="297"
          y2="106.7"
        />
        <line
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 6;
            stroke-miterlimit: 10;
          "
          x1="1"
          y1="106.7"
          x2="88.2"
          y2="106.7"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ElphinLogo",
};
</script>

<style scoped></style>
