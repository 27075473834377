<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 980 85"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M10.1,81.5l0-79.5L63,2l0,6.4l-45.4,0l0,28.7l41,0l0,6.4l-41,0l0,31.6l46.5,0l0,6.4L10.1,81.5z"
        />
        <path
          class="st0"
          d="M82.3,81.5l-6.6,0l0-57.2l6.6,0l0,8.6h0.2c1.7-3.2,4.1-5.7,7.3-7.5c3.2-1.8,6.6-2.7,10.4-2.7
			c4.9,0,8.5,1,11,2.9c2.5,1.9,4.4,4.6,5.8,8.2c1.8-3.6,4.4-6.4,7.6-8.2c3.2-1.9,6.9-2.8,11-2.8c3.8,0,6.8,0.6,9.2,1.8
			c2.4,1.2,4.2,2.9,5.4,5c1.3,2.1,2.1,4.4,2.5,7c0.4,2.6,0.7,5.5,0.7,8.8l0,36.1l-6.6,0l0-39.5c0-4.3-0.9-7.6-2.8-9.9
			c-1.8-2.2-5-3.4-9.5-3.4c-2.5,0-4.9,0.6-7.1,1.7c-2.2,1.1-4.2,2.7-5.8,4.9c-1.3,1.7-2.3,4-2.9,6.9c-0.6,2.9-0.9,5.8-0.9,8.6
			l0,30.8l-6.6,0l0-39.5c0-4.3-0.9-7.6-2.8-9.9c-1.8-2.2-5-3.4-9.5-3.4c-2.5,0-4.9,0.6-7.1,1.7c-2.2,1.1-4.2,2.7-5.8,4.9
			c-1.3,1.7-2.3,4-2.9,6.9c-0.6,2.9-0.9,5.8-0.9,8.6L82.3,81.5z"
        />
        <path
          class="st0"
          d="M175.9,81.5l-6.6,0l0-79.5l6.6,0l0,31.5c2.1-3.4,5-6,8.7-7.9c3.7-1.9,7.6-2.8,11.8-2.8c4.3,0,8,0.8,11.2,2.4
			c3.2,1.6,5.9,3.8,8,6.6c2.1,2.8,3.7,6.1,4.7,9.7c1,3.6,1.5,7.5,1.5,11.6c0,4.3-0.5,8.3-1.5,12c-1,3.6-2.5,6.8-4.6,9.5
			c-2.1,2.7-4.7,4.8-8.1,6.3c-3.3,1.5-7.2,2.3-11.7,2.3c-3.8,0-7.6-1-11.4-3.1c-3.8-2.1-6.6-4.9-8.4-8.5l-0.2,0L175.9,81.5z
			 M181.1,70.2c3.4,4.6,8.2,6.8,14.2,6.8c5.7,0,10.4-2,14-6c3.6-4,5.4-9.5,5.5-16.5c0-8-1.7-14.3-5-18.9c-3.3-4.7-8.2-7-14.6-7
			c-2.9,0-5.6,0.6-8,1.9c-2.4,1.3-4.4,3-6.1,5.2c-1.7,2.2-3,4.8-3.9,7.7c-0.9,2.9-1.3,6-1.3,9.2C175.9,59.8,177.7,65.7,181.1,70.2z"
        />
        <path
          class="st0"
          d="M243,81.5l-6.6,0l0-57.2l6.6,0l0,10.7l0.2,0c1.8-4.5,4.1-7.7,7-9.5c2.9-1.8,6.8-2.8,11.7-2.8l0,7.2
			c-3.6,0-6.6,0.6-9.1,1.8c-2.5,1.2-4.4,2.9-5.9,5c-1.4,2.1-2.4,4.6-3,7.4c-0.6,2.8-0.9,5.9-0.9,9.3L243,81.5z"
        />
        <path
          class="st0"
          d="M299.1,48.1c1.5-0.1,3.1-0.4,4.7-0.7c1.7-0.3,3-0.7,4-1.2c1-0.4,1.6-1.2,1.9-2.3c0.3-1.1,0.5-2.3,0.5-3.7
			c0-3.6-1.1-6.4-3.4-8.5c-2.3-2.1-5.9-3.1-10.8-3.1c-4.9,0-8.8,1-11.6,2.9c-2.8,2-4.5,5.3-5.1,10l-6.7,0c0.4-6.4,2.8-11.2,7.1-14.2
			c4.3-3.1,9.8-4.6,16.4-4.6c6.3,0,11.4,1.4,15.1,4.2c3.8,2.8,5.6,7.3,5.6,13.6l0,31.1c0,3.5,1,5.3,3,5.3c1,0,2.3-0.3,4-0.9l0,5.2
			c-0.9,0.3-1.8,0.6-2.7,0.8s-1.7,0.3-2.5,0.3c-1,0-2-0.1-3-0.3c-2-0.3-3.4-1.2-4.1-2.7c-0.7-1.5-1.1-3.4-1.2-5.9
			c-2.7,3.1-5.9,5.4-9.6,7.1c-3.7,1.6-7.6,2.4-11.7,2.4c-5.4,0-9.9-1.4-13.6-4.3c-3.7-2.9-5.5-6.9-5.5-12.1c0-9.7,6.7-15.5,20-17.2
			L299.1,48.1z M310.2,51.4c-2.5,1-5,1.8-7.6,2.2c-2.5,0.4-5.7,0.8-9.6,1c-5.2,0.4-9.2,1.5-12,3.5c-2.8,2-4.1,4.9-4.1,8.6
			c0,3.3,1.2,5.9,3.6,7.6c2.4,1.8,5.3,2.7,8.7,2.7c2.7,0,5.3-0.3,7.8-1c2.5-0.7,4.7-1.7,6.7-3c2-1.4,3.6-3.1,4.7-5.2
			c1.1-2.1,1.7-4.6,1.7-7.4L310.2,51.4z"
        />
        <path
          class="st0"
          d="M374.7,42.3c-1.2-4.3-3.2-7.7-6.1-10.1c-2.9-2.4-6.5-3.5-10.9-3.5c-3.2,0-6.1,0.7-8.6,2
			c-2.5,1.4-4.5,3.2-6.1,5.5c-1.6,2.3-2.7,4.9-3.5,7.8c-0.7,2.9-1.1,5.9-1.1,8.9s0.4,6.1,1.1,8.9c0.7,2.9,1.9,5.4,3.5,7.7
			c1.6,2.3,3.6,4.1,6,5.4c2.4,1.3,5.3,2,8.6,2c4.9,0,8.8-1.4,11.8-4.1c3-2.7,4.9-6.5,5.7-11.3l7.1,0c-1.2,6.6-3.8,11.8-8,15.6
			c-4.2,3.8-9.7,5.7-16.6,5.7c-4.4,0-8.3-0.8-11.7-2.3c-3.4-1.5-6.1-3.6-8.2-6.4c-2.1-2.7-3.7-5.9-4.8-9.6
			c-1.1-3.6-1.6-7.6-1.6-11.9c0-4,0.6-7.9,1.7-11.5c1.1-3.6,2.8-6.8,5.1-9.7c2.2-2.8,5-5.1,8.3-6.7c3.3-1.6,7-2.4,11.2-2.4
			c6.7,0,12.1,1.6,16.2,4.9c4.1,3.3,6.6,8.2,7.6,14.6L374.7,42.3z"
        />
        <path
          class="st0"
          d="M399.7,54.4c0,6.6,1.7,12,5,16.2c3.3,4.3,8.1,6.4,14.2,6.4c4.3,0,8.1-1.2,11.2-3.5c3.1-2.3,5.2-5.5,6.1-9.7
			l7.4,0c-1.8,6-4.7,10.6-8.6,14c-3.9,3.4-9.3,5.1-16.1,5.1c-4.4,0-8.3-0.7-11.7-2.2c-3.4-1.5-6.1-3.6-8.3-6.3
			c-2.2-2.7-3.8-5.9-4.8-9.5c-1-3.6-1.5-7.6-1.5-11.9c0-4.2,0.6-8.1,1.8-11.8c1.2-3.7,3-6.9,5.2-9.7c2.3-2.8,5.1-5,8.3-6.5
			c3.3-1.5,6.9-2.3,10.9-2.3c7.9,0,14,2.7,18.5,8.2c4.5,5.4,6.7,13.3,6.7,23.5L399.7,54.4z M437,48.9c0-5.8-1.6-10.6-4.7-14.5
			c-3.2-3.8-7.6-5.7-13.4-5.7c-5.5,0-9.9,1.9-13.2,5.7c-3.3,3.8-5.3,8.7-6,14.5L437,48.9z"
        />
        <path
          class="st0"
          d="M481.4,81.5L512.7,2l8.5,0l30.5,79.5l-7.7,0l-9.2-25l-36.2,0l-9.4,25L481.4,81.5z M501.1,50.1l31.1,0
			L516.7,8.4L501.1,50.1z"
        />
        <path
          class="st0"
          d="M568.4,81.5l-6.6,0l0-57.2l6.6,0l0,10.7l0.2,0c1.8-4.5,4.1-7.7,7-9.5c2.9-1.8,6.8-2.8,11.7-2.8l0,7.2
			c-3.6,0-6.6,0.6-9.1,1.8c-2.5,1.2-4.4,2.9-5.9,5c-1.4,2.1-2.4,4.6-3,7.4c-0.6,2.8-0.9,5.9-0.9,9.3L568.4,81.5z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M636.7,42.3c-1.2-4.3-3.2-7.7-6.1-10.1c-2.9-2.4-6.5-3.5-10.9-3.5c-3.2,0-6.1,0.7-8.6,2
			c-2.5,1.4-4.5,3.2-6.1,5.5c-1.6,2.3-2.7,4.9-3.5,7.8c-0.7,2.9-1.1,5.9-1.1,8.9s0.4,6.1,1.1,8.9c0.7,2.9,1.9,5.4,3.5,7.7
			c1.6,2.3,3.6,4.1,6,5.4c2.4,1.3,5.3,2,8.6,2c4.9,0,8.8-1.4,11.8-4.1c3-2.7,4.9-6.5,5.7-11.3l7.1,0c-1.2,6.6-3.8,11.8-8,15.6
			c-4.2,3.8-9.7,5.7-16.6,5.7c-4.4,0-8.3-0.8-11.7-2.3c-3.4-1.5-6.1-3.6-8.2-6.4c-2.1-2.7-3.7-5.9-4.8-9.6
			c-1.1-3.6-1.6-7.6-1.6-11.9c0-4,0.6-7.9,1.7-11.5c1.1-3.6,2.8-6.8,5.1-9.7c2.2-2.8,5-5.1,8.3-6.7c3.3-1.6,7-2.4,11.2-2.4
			c6.7,0,12.1,1.6,16.2,4.9c4.1,3.3,6.6,8.2,7.6,14.6L636.7,42.3z"
        />
        <path
          class="st0"
          d="M657.6,81.5l0-79.5l6.6,0l0,30.3c2.9-3.2,5.8-5.5,8.7-7.1c2.8-1.6,6.1-2.4,9.8-2.4c3.7,0,7,0.6,10.1,1.9
			c3,1.3,5.5,3.4,7.4,6.2c1.3,1.8,2,3.7,2.4,5.7c0.3,2,0.5,4.4,0.5,7.5l0,37.4l-6.6,0l0-37.3c0-5.2-1.2-9.1-3.5-11.7
			c-2.3-2.5-6.1-3.8-11.3-3.8c-3.8,0-7.2,1.1-10,3.2c-2.8,2.1-4.8,4.8-5.9,8c-0.6,1.6-1,3.2-1.2,4.9c-0.2,1.6-0.3,3.6-0.3,6l0,30.8
			L657.6,81.5z"
        />
        <path
          class="st0"
          d="M719.7,11.8l0-9.8l7.3,0l0,9.8L719.7,11.8z M720,81.5l0-57.2l6.6,0l0,57.2L720,81.5z"
        />
        <path
          class="st0"
          d="M763.6,81.7c-0.1,0.1-0.6,0.2-1.2,0.3c-2.7,0.7-4.8,1-6.4,1c-3.4,0-5.9-0.9-7.6-2.6c-1.7-1.7-2.5-4.7-2.5-8.8
			l0-41.9l-8.1,0l0-5.5l8.1,0l0-15.9l6.6,0l0,15.9l10.7,0l0,5.5l-10.7,0l0,39.2c0,3.2,0.3,5.3,0.9,6.4c0.6,1.1,2.2,1.7,4.8,1.7
			c1.5,0,3.3-0.3,5.4-0.8L763.6,81.7z"
        />
        <path
          class="st0"
          d="M778.3,54.4c0,6.6,1.7,12,5,16.2c3.3,4.3,8.1,6.4,14.2,6.4c4.3,0,8.1-1.2,11.2-3.5c3.1-2.3,5.2-5.5,6.1-9.7
			l7.4,0c-1.8,6-4.7,10.6-8.6,14c-3.9,3.4-9.3,5.1-16.1,5.1c-4.4,0-8.3-0.7-11.7-2.2c-3.4-1.5-6.1-3.6-8.3-6.3
			c-2.2-2.7-3.8-5.9-4.8-9.5c-1-3.6-1.5-7.6-1.5-11.9c0-4.2,0.6-8.1,1.8-11.8c1.2-3.7,3-6.9,5.2-9.7c2.3-2.8,5.1-5,8.3-6.5
			c3.3-1.5,6.9-2.3,10.9-2.3c7.9,0,14,2.7,18.5,8.2c4.5,5.4,6.7,13.3,6.7,23.5L778.3,54.4z M815.7,48.9c0-5.8-1.6-10.6-4.7-14.5
			c-3.2-3.8-7.6-5.7-13.4-5.7c-5.5,0-9.9,1.9-13.2,5.7c-3.3,3.8-5.3,8.7-6,14.5L815.7,48.9z"
        />
        <path
          class="st0"
          d="M876.2,42.3c-1.2-4.3-3.2-7.7-6.1-10.1c-2.9-2.4-6.5-3.5-10.9-3.5c-3.2,0-6.1,0.7-8.6,2
			c-2.5,1.4-4.5,3.2-6.1,5.5c-1.6,2.3-2.7,4.9-3.5,7.8c-0.7,2.9-1.1,5.9-1.1,8.9s0.4,6.1,1.1,8.9c0.7,2.9,1.9,5.4,3.5,7.7
			c1.6,2.3,3.6,4.1,6,5.4c2.4,1.3,5.3,2,8.6,2c4.9,0,8.8-1.4,11.8-4.1c3-2.7,4.9-6.5,5.7-11.3l7.1,0c-1.2,6.6-3.8,11.8-8,15.6
			c-4.2,3.8-9.7,5.7-16.6,5.7c-4.4,0-8.3-0.8-11.7-2.3c-3.4-1.5-6.1-3.6-8.2-6.4c-2.1-2.7-3.7-5.9-4.8-9.6
			c-1.1-3.6-1.6-7.6-1.6-11.9c0-4,0.6-7.9,1.7-11.5c1.1-3.6,2.8-6.8,5.1-9.7c2.2-2.8,5-5.1,8.3-6.7c3.3-1.6,7-2.4,11.2-2.4
			c6.7,0,12.1,1.6,16.2,4.9c4.1,3.3,6.6,8.2,7.6,14.6L876.2,42.3z"
        />
        <path
          class="st0"
          d="M917.2,81.7c-0.1,0.1-0.6,0.2-1.2,0.3c-2.7,0.7-4.8,1-6.4,1c-3.4,0-5.9-0.9-7.6-2.6c-1.7-1.7-2.5-4.7-2.5-8.8
			l0-41.9l-8.1,0l0-5.5l8.1,0l0-15.9l6.6,0l0,15.9l10.7,0l0,5.5l-10.7,0l0,39.2c0,3.2,0.3,5.3,0.9,6.4c0.6,1.1,2.2,1.7,4.8,1.7
			c1.5,0,3.3-0.3,5.4-0.8L917.2,81.7z"
        />
        <path
          class="st0"
          d="M931.6,63.6c1,4.9,2.9,8.3,5.6,10.4c2.7,2.1,6.5,3.1,11.5,3.1c4.6,0,8.1-1,10.5-3.1c2.4-2.1,3.6-4.6,3.6-7.5
			c0-1.8-0.4-3.4-1.3-4.6c-0.8-1.3-2-2.2-3.5-3c-1.5-0.7-3.1-1.4-4.9-1.9c-1.8-0.5-4-1-6.6-1.5c-3.3-0.7-6-1.3-8.2-1.9
			c-2.1-0.6-4.1-1.5-6-2.6c-1.9-1.1-3.4-2.6-4.5-4.6c-1.1-2-1.7-4.4-1.7-7.2c0-4.9,1.8-8.8,5.4-11.8c3.6-3,8.5-4.5,14.7-4.5
			c6.4,0,11.5,1.4,15.2,4.3c3.8,2.9,6,7.4,6.8,13.7l-6.7,0c-0.6-4.2-2.2-7.3-4.9-9.2c-2.7-1.9-6.2-2.9-10.6-2.9
			c-4.1,0-7.3,0.9-9.6,2.7c-2.2,1.8-3.4,4.2-3.4,7.2c0,1.7,0.4,3.1,1.3,4.4c0.8,1.2,2,2.2,3.4,2.9c1.4,0.7,3,1.4,4.6,1.9
			c1.7,0.5,3.9,1.1,6.7,1.8c3.5,0.8,6.2,1.5,8.3,2.2c2.1,0.7,4.1,1.6,6,2.8c2,1.2,3.5,2.8,4.6,4.7c1.1,1.9,1.7,4.3,1.7,7.2
			c0,5.3-2.1,9.4-6.4,12.3c-4.3,2.9-9.9,4.4-16.9,4.4c-6.6,0-11.7-1.8-15.6-5.5c-3.8-3.7-5.9-8.3-6.2-13.9L931.6,63.6z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmbraceLogo",
};
</script>

<style scoped>
.st0 {
  fill: currentColor;
}
</style>
