<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 600 300"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            class="st0"
            d="M194.2,123.9c0-36.2-23.9-46.7-42.2-46.7c-12.4,0-24.5,4.9-34.8,12.6V24.8H77v7.8v2.9v7.8h21.4v128.5H77v7.8
				v2.9v7.8h61.8v-7.8v-2.9v-7.8h-21.6v-57c9.7-11.5,22.4-19.2,34.8-19.2c12.2,0,23.5,5.6,23.5,28.2v66.5h40.4v-7.8v-2.9v-7.8h-21.6
				V123.9z"
          />
          <path
            class="st0"
            d="M470,77.1c-35.4,0-57.9,27.6-57.9,57.9c0,31.5,24.5,57.5,59.9,57.5c16.7,0,33.4-6.6,46.3-17.3l-5.1-6
				l-1.9-2.1l-4.9-6.2c-10.3,8.4-21.4,13-34.4,13c-21.2,0-36.2-12.8-40.2-29.9h87.9C531.4,113.6,510.4,77.1,470,77.1z M470,95.7
				c20.8,0,33.4,14.6,34,29.9h-72.1C435.6,108.9,449.8,95.7,470,95.7z"
          />
          <polygon
            class="st0"
            points="415.4,89.9 415.4,87 415.4,79.2 355.6,79.2 355.6,87 355.6,89.9 355.6,97.7 374.6,97.7 344.9,165.7
				315.3,97.7 334.2,97.7 334.2,89.9 334.2,87 334.2,79.2 274.5,79.2 274.5,87 274.5,89.9 274.5,97.7 295.1,97.7 336.4,192.3
				344.9,192.3 353.3,192.3 394.8,97.7 415.4,97.7 			"
          />
          <polygon
            class="st0"
            points="263.6,79.2 223.4,79.2 223.4,87 223.4,89.9 223.4,97.7 245.1,97.7 245.1,171.9 223.4,171.9
				223.4,179.7 223.4,182.6 223.4,190.4 285.2,190.4 285.2,182.6 285.2,179.7 285.2,171.9 263.6,171.9 			"
          />
          <path
            class="st0"
            d="M252.1,60.7c8.6,0,15.7-7,15.7-15.7c0-8.4-7-15.4-15.7-15.4c-8.4,0-15.4,7-15.4,15.4
				C236.6,53.7,243.6,60.7,252.1,60.7z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M108,244.8c6.5,0,9.7,2.8,9.7,7.9v3.2c0,5.1-3.2,7.9-9.7,7.9h-7.3v11.1h-6v-30H108z M100.7,258.8h6.7
				c3.1,0,4.3-1.1,4.3-3.7v-1.7c0-2.7-1.2-3.8-4.3-3.8h-6.7V258.8z"
          />
          <path
            class="st0"
            d="M153.2,244.8c6.5,0,9.7,2.7,9.7,7.6v3.1c0,3.7-1.8,6-5.7,6.9l6.6,12.5h-6.6l-5.7-11.8h-5.6v11.8h-6v-30
				H153.2z M145.9,258.2h6.7c3.1,0,4.3-1,4.3-3.5v-1.5c0-2.5-1.2-3.6-4.3-3.6h-6.7V258.2z"
          />
          <path
            class="st0"
            d="M200.2,244.4c7.4,0,10.7,3.7,10.7,11v9.4c0,7.4-3.6,10.4-10.7,10.4h-4c-7,0-10.7-3.1-10.7-10.4v-9.4
				c0-7.2,3.3-11,10.7-11H200.2z M196.7,249.2c-3.7,0-5.3,1.8-5.3,6.2v9.2c0,4.4,1.7,5.8,5.3,5.8h2.9c3.7,0,5.3-1.4,5.3-5.8v-9.2
				c0-4.4-1.6-6.2-5.3-6.2H196.7z"
          />
          <path
            class="st0"
            d="M246.9,244.8c6.5,0,9.7,2.8,9.7,7.9v3.2c0,5.1-3.2,7.9-9.7,7.9h-7.3v11.1h-6v-30H246.9z M239.6,258.8h6.7
				c3.1,0,4.3-1.1,4.3-3.7v-1.7c0-2.7-1.2-3.8-4.3-3.8h-6.7V258.8z"
          />
          <path
            class="st0"
            d="M284.9,249.6v7.3h13.9v4.9h-13.9v8.2h14.9v4.9h-20.9v-30h20.6v4.9H284.9z"
          />
          <path
            class="st0"
            d="M335.1,244.8c6.5,0,9.7,2.7,9.7,7.6v3.1c0,3.7-1.8,6-5.7,6.9l6.6,12.5h-6.6l-5.7-11.8h-5.6v11.8h-6v-30
				H335.1z M327.8,258.2h6.7c3.1,0,4.3-1,4.3-3.5v-1.5c0-2.5-1.2-3.6-4.3-3.6h-6.7V258.2z"
          />
          <path
            class="st0"
            d="M380.4,249.6v25.2h-6v-25.2h-8.9v-4.9h23.9v4.9H380.4z"
          />
          <path class="st0" d="M410.8,274.8v-30h6v30H410.8z" />
          <path
            class="st0"
            d="M445.8,249.6v7.3h13.9v4.9h-13.9v8.2h14.9v4.9h-20.9v-30h20.6v4.9H445.8z"
          />
          <path
            class="st0"
            d="M481.8,251.9c0-4.6,2.2-7.6,11.2-7.6c9.2,0,11.3,2.7,11.3,7.4l-5.9,0.3c0-1.8-0.9-3-5.3-3
				c-4.4,0-5.2,1.2-5.2,3v1.5c0.1,2,1.3,2.4,6.2,2.8c6.6,0.5,11.4,1.6,11.4,8.2v3.2c0,5.3-3.6,7.3-12.3,7.3
				c-8.2,0-11.6-1.6-11.6-7.8l5.7-0.3c0,2.2,1.3,3.3,6,3.3c4.8,0,6.3-1.2,6.3-3.1v-1.6c0-2.8-1.8-3.1-6.7-3.5
				c-6.9-0.6-10.7-1.8-10.8-7V251.9z"
          />
        </g>
      </g>
      <g>
        <path
          class="st0"
          d="M581,69.8c0,14.9-12.1,26.9-26.9,26.9c-14.9,0-26.9-12.1-26.9-26.9s12.1-26.9,26.9-26.9
			C568.9,42.9,581,55,581,69.8z M533.4,69.8c0,11.8,9.4,21.2,20.7,21.2c11.3,0,20.7-9.4,20.7-21.2c0-11.8-9.4-21.2-20.7-21.2
			C542.8,48.6,533.4,58,533.4,69.8z M555.5,56.3c5.8,0,8.8,2.5,8.8,6.9v2.6c0,3.2-1.6,5.3-5,6.1l6,11.2H559l-5.1-10.4h-4.5v10.4
			h-5.7V56.3H555.5z M555.1,68.1c2.7,0,3.6-0.8,3.6-2.9v-1.3c0-2.2-0.9-2.9-3.6-2.9h-5.7v7.2H555.1z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HiveLogo",
};
</script>

<style scoped>
.st0 {
  fill: currentColor;
}
</style>
